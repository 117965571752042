import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Doc from "../../../components/Doc"

const Law = () => (
  <Layout mode={`default`}>
    <SEO title={`特定商取引法に基づく表記`} />
    <div className={`l-wrap`}>
      <Doc>
        <div className="wrapHINAGATA">
          <h1>オンラインショップご利用規約</h1>
          <p>
            この利用規約（以下，「本規約」といいます。）は，TRANS BOOKS
            DOWNLOADs（以下，「TBDs」といいます。）がこのウェブサイト上で提供するオンラインショップ（以下，「本サービス」といいます。）の利用条件を定めるものです。ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。
          </p>
          <h2>第1条（適用）</h2>
          <ol>
            <li>
              本規約は，ユーザーとTBDsとの間の本サービスの利用に関わる一切の関係に適用されるものとします。
            </li>
            <li>
              TBDsは本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下,「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
            </li>
            <li>
              本規約の定めが前項の個別規定の定めと矛盾する場合には，個別規定において特段の定めなき限り，個別規定の定めが優先されるものとします。
            </li>
          </ol>
          <h2>第2条（利用登録）</h2>
          <ol>
            <li>
              本サービスは決済システムに{" "}
              <a
                href={`https://gumroad.com/`}
                target={`_blank`}
                rel={`noopener noreferrer`}
              >
                Gumroad
              </a>{" "}
              を使用します。決済については{" "}
              <a
                href={`https://gumroad.com/`}
                target={`_blank`}
                rel={`noopener noreferrer`}
              >
                Gumroad
              </a>{" "}
              の利用規約に従います。
            </li>
          </ol>
          <h2>第3条（売買契約）</h2>
          <ol>
            <li>
              本サービスにおいては，ユーザーがTBDsに対して Gumroad
              を通じて，購入の申し込みをし，Gumroad
              での購入手続きを経て、売買契約が成立するものとします。なお，当該商品の所有権は，ユーザが
              Gumroad
              からの通知をうけ、ダウンロードしたときに，ユーザーに移転するものとします。
            </li>
            <li>
              TBDsは，ユーザーが以下のいずれかの事由に該当する場合には，当該ユーザーに事前に通知することなく，前項の売買契約を解除することができるものとします。
              <ol>
                <li>ユーザーが本規約に違反した場合</li>
                <li>
                  ブラウザやインターネット環境の状況により購入が困難な場合
                </li>
                <li>その他TBDsとユーザーの信頼関係が損なわれたと認める場合</li>
              </ol>
            </li>
            <li>
              本サービスに関する決済方法，配送方法，購入の申し込みのキャンセル方法，別途TBDsが定める方法によります。
            </li>
            <li>
              本サービスで購入する商品は、デジタルデータの特性上、返品を受け付けません。
            </li>
          </ol>
          <h2>第4条（知的財産権）</h2>
          <p>
            本サービスによって提供される商品写真その他のコンテンツ（以下「コンテンツ」といいます）の著作権又はその他の知的所有権は,TBDs及びコンテンツ提供者である各作家などの正当な権利者に帰属し,ユーザーは,これらを無断で複製,転載,改変,その他の二次利用をすることはできません。
          </p>
          <p>
            一部の商品には Creative Commons
            等のライセンスが付与されています。それらの商品においてはライセンスで許可された範囲での二次利用が可能です。
          </p>
          <h2>第5条（禁止事項）</h2>
          <p>
            ユーザーは，本サービスの利用にあたり，以下の行為をしてはならないものとします。
          </p>
          <ol>
            <li>法令または公序良俗に違反する行為</li>
            <li>犯罪行為に関連する行為</li>
            <li>
              本サービスに含まれる著作権，商標権その他の知的財産権を侵害する行為
            </li>
            <li>
              TBDsのサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
            </li>
            <li>本サービスによって得られた情報を商業的に利用する行為</li>
            <li>TBDsのサービスの運営を妨害するおそれのある行為</li>
            <li>不正アクセスをし，またはこれを試みる行為</li>
            <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
            <li>他のユーザーに成りすます行為</li>
            <li>
              TBDsのサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
            </li>
            <li>その他，TBDsが不適切と判断する行為</li>
          </ol>
          <h2>第6条（本サービスの提供の停止等）</h2>
          <ol>
            <li>
              TBDsは，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
              <ol>
                <li>
                  本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
                </li>
                <li>
                  地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
                </li>
                <li>コンピュータまたは通信回線等が事故により停止した場合</li>
                <li>その他，TBDsが本サービスの提供が困難と判断した場合</li>
              </ol>
            </li>
            <li>
              TBDsは，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害について，理由を問わず一切の責任を負わないものとします。
            </li>
          </ol>
          <h2>第7条（利用制限および登録抹消）</h2>
          <ol>
            <li>
              TBDsは，以下のいずれかに該当する場合には，事前の通知なく，ユーザーに対して，本サービスの全部もしくは一部の利用を制限し，またはユーザーの利用を禁じることができるものとします。
              <ol>
                <li>本規約のいずれかの条項に違反した場合</li>
                <li>登録事項に虚偽の事実があることが判明した場合</li>
                <li>
                  決済手段として当該ユーザーが届け出たクレジットカードが利用停止となった場合
                </li>
                <li>料金等の支払債務の不履行があった場合</li>
                <li>TBDsからの連絡に対し，一定期間返答がない場合</li>
                <li>
                  本サービスについて，最終の利用から一定期間利用がない場合
                </li>
                <li>
                  その他，TBDsが本サービスの利用を適当でないと判断した場合
                </li>
              </ol>
            </li>
            <li>
              TBDsは，本条に基づきTBDsが行った行為によりユーザーに生じた損害について，一切の責任を負いません。
            </li>
          </ol>
          <h2>第8条（保証の否認および免責事項）</h2>
          <ol>
            <li>
              TBDsは,本サービスに事実上または法律上の瑕疵（安全性,信頼性,正確性,完全性,有効性,特定の目的への適合性,セキュリティなどに関する欠陥,エラーやバグ,権利侵害などを含みます。）がないことを保証するものではありません。
            </li>
            <li>
              TBDsは,本サービスによってユーザーに生じたあらゆる損害について,一切の責任を負いません。ただし,本サービスに関するTBDsとユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合,この免責規定は適用されませんが,この場合であっても,TBDsは,TBDsの過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（TBDsまたはユーザーが損害発生につき予見し,または予見し得た場合を含みます。）について一切の責任を負いません。
            </li>
            <li>
              TBDsは，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。
            </li>
          </ol>
          <h2>第9条（サービス内容の変更等）</h2>
          <p>
            TBDsは，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。
          </p>
          <h2>第10条（利用規約の変更）</h2>
          <p>
            TBDsは，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該ユーザーは変更後の規約に同意したものとみなします。
          </p>
          <h2>第11条（個人情報の取扱い）</h2>
          <p>
            TBDsは，本サービスの利用によって取得する個人情報については，TBDs「プライバシーポリシー」に従い適切に取り扱うものとします。
          </p>
          <h2>第12条（通知または連絡）</h2>
          <p>
            ユーザーとTBDsとの間の通知または連絡は，TBDsの定める方法によって行うものとします。TBDsは,ユーザーから,TBDsが別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。
          </p>
          <h2>第13条（権利義務の譲渡の禁止）</h2>
          <p>
            ユーザーは，TBDsの書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。
          </p>
          <h2>第14条（準拠法・裁判管轄）</h2>
          <ol>
            <li>
              本規約の解釈にあたっては，日本法を準拠法とします。なお，本サービスに関しては，国際物品売買契約に関する国際連合条約の適用を排除するものとします。
            </li>
            <li>
              本サービスに関して紛争が生じた場合には，TBDsの本店所在地を管轄する裁判所を専属的合意管轄裁判所とします。
            </li>
          </ol>

          <hr />

          <h1>推奨環境</h1>
          <p>
            本サイトを正常かつ快適にご利用いただくために、以下のOS・ブラウザを推奨いたします。なお、以下のOS・ブラウザ以外の環境では閲覧できない可能性がございますのでご了承ください。
          </p>
          <h2>OS</h2>
          <ul>
            <li>iOS / Android / Windows / Mac</li>
          </ul>
          <h2>推奨ブラウザ</h2>
          <ul>
            <li> Google Chrome 最新版</li>
            <li> FireFox 最新版</li>
            <li> Safari 最新版</li>
            <li> Microsoft Edge 最新版 以上</li>
          </ul>
          <p className="tR">以上</p>
        </div>
      </Doc>
    </div>
  </Layout>
)

export default Law
